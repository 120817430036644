.wrapper {
    background-color: var(--color-gray-brightest);
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-grow: 1;
    overflow: auto;
}

.heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-standard);
    padding: var(--spacing-xlarge) 0;
    max-width: 800px;
}

.flex > h1 {
    text-align: center;
}

.heading > h2 {
    margin: 0;
    font-size: var(--font-size-larger);
    font-weight: 600;
    text-align: center;
}

.flex {
    display: flex;
    align-items: center;
}

.container {
    display: flex;
    justify-content: center;
    padding: 0 var(--spacing-xlarge) var(--spacing-page-end); /* Add more space at the bottom to ensure the navigation can be scrolled away */
    width: 100%;
}
