.wrapper {
    /*
     * Oh Daniel, my dear friend, I just want to express my utmost gratitude to you for not knowing how to hide a
     * navbar on a page! Your lack of knowledge has brought me such joy and excitement as I struggled to put content
     * over a fixed navbar. I mean, who needs an easy and efficient way to design a website when you can spend hours
     * trying to figure out a workaround? Thanks to you, I have become a master of creative problem-solving and my
     * frustration tolerance has reached new heights!
     * So, thank you, Daniel, for not knowing how to hide a navbar. Without your lack of expertise, I would never
     * have experienced such a thrilling challenge in my web design journey. You truly are a blessing in disguise!
     */
    /* TODO: Remove once not rendered by blazor app */
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
    display: grid;
    grid-template-columns: 5% 450px 1fr;
}

.wiredGreySpace {
    background-color: var(--color-gray-darkest);
}

.form {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: var(--spacing-xlarge);
    background-color: var(--color-background);
    overflow-y: auto;
    overflow-x: hidden;
}

.closeSection {
    position: absolute;
    top: var(--spacing-xlarge);
    left: var(--spacing-xlarge);
    height: 2rem;
}

.inputs {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
}

.image {
    background-image: url(https://treditionstorage.blob.core.windows.net/pageimages/7ffd5a12-de44-43d5-b67f-e29f725d0d39.jpg);
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    flex-grow: 1;
}

.small {
    width: 180px;
}

.returnSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: var(--spacing-standard) 0;
}

/* TODO: size zum underlined button hinzufügen  */
.returnSection .backToLogin {
    font-size: var(--font-size-small);
}

.returnSection .backToLogin:before {
    height: 40%;
}

.passwordChecks {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
}

.check {
    display: flex;
    font-size: var(--font-size-small);
    gap: var(--spacing-small);
}

.valid {
    color: var(--color-mint-darker);
}

.invalid {
    color: var(--color-danger-dark);
}

.flex {
    display: flex;
    align-items: center;
    gap: var(--spacing-medium);
}

.flex > * {
    flex-grow: 1;
}

.successWrapper {
    padding: 0 var(--spacing-standard);
}

.successWrapper .headline {
    font-size: var(--font-size-xlarge);
    padding-bottom: var(--spacing-xlarge);
}

.successWrapper .text {
    font-size: var(--font-size-medium);
    font-weight: 500;
}

.submit {
    font-weight: 600;
}

.text {
    font-size: var(--font-size-small);
    font-weight: 600;
}

.regionGroup {
    position: relative;
}

.info {
    position: absolute;
    top: -6px;
    right: 95px; /* Sandra möchte den Button am Text, packe ich ihn aber ins Label, passen die Spacings nicht mehr */
}
