/*.work-space {*/
/*  position: relative;*/
/*  width: 100%;*/
/*  background: #cccccc;*/
/*  overflow: auto;*/
/*}*/

/*.work-space:not(.active):hover {*/
/*  background: #cfcfcf;*/
/*}*/

/*.work-space.active:not(.single) {*/
/*  outline: 2px solid #8ce699;*/
/*  outline-offset: -2px;*/
/*}*/

/*.work-space.active {*/
/*  background: #f2f2f2;*/
/*}*/

.work-space-separator {
  background: #ddd;
  text-align: center;
  padding: 1rem 0;
  /* FIXME: I have no idea why this element is below the work-space (z: auto) even though it is rendered after the workspace */
  position: relative;
  z-index: 1;
}

/*.work-space-overlay {*/
/*  position: absolute;*/
/*  left: 0;*/
/*  top: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*}*/

/*.work-space.active .work-space-overlay {*/
/*  display: none;*/
/*}*/
