.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
    width: 600px;
}

.flex {
    display: flex;
    gap: var(--spacing-medium);
    align-items: baseline;
}

.flex > div {
    flex: 2 2 200px;
}

.flex > div:first-child {
    flex: 1 1 150px;
}
