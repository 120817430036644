.wrapper {
    background-color: var(--color-background);
    display: flex;
    flex-direction: column;
    border-radius: var(--border-radius);
    max-width: 1000px;
    width: 100%;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.headline {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    gap: var(--spacing-standard);
    margin-top: var(--spacing-standard);
    margin-right: var(--spacing-standard);
}

.imprintContainer {
    flex: 0 0 50%;
}

.infoWrapper {
    display: flex;
    gap: var(--spacing-standard);
    align-items: center;
}

.wrapper .infoWrapper > h2 {
    font-size: var(--font-size-large);
    font-weight: 500;
}

.questionGroup {
    padding: var(--spacing-standard);
    border-top: 1px solid var(--color-border);
}

.questionGroup:first-child {
    padding: var(--spacing-standard);
    border-top: none;
}
