.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
    width: 600px;
}

.flex {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: var(--spacing-standard);
}

.large{
    flex: 1 1;
}

.small {
    width: 150px;
}

.addNew {
    display: flex;
    align-items: center;
    gap: var(--spacing-small);
    cursor: pointer;
    font-size: var(--font-size-medium);
    font-weight: 500;
    padding-top: var(--spacing-standard);
}

.addNew.disabled {
    cursor: not-allowed;
}
