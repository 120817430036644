.proportions {
    border: 1px dashed var(--color-border-dark);
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
}

.proportions.dina4 {
    width: 210px;
    height: 297px;
    max-width: 210px;
    max-height: 297px;
    --width: 210px;
    --height: 297px;
}

.proportions.usLetter {
    width: 215.9px;
    height: 279.4px;
    max-width: 215.9px;
    max-height: 279.4px;
    --width: 215.9px;
    --height: 279.4px;
}

.formatTitle {
    font-size: var(--font-size-small);
    font-weight: 500;
    color: var(--color-border-dark)
}

.currentProportion {
    transition: all 500ms;
    width: var(--width);
    height: var(--height);

    background-color: var(--color-forst);
    color: var(--color-background);
    font-weight: 500;

    padding: 0;
    margin: 0;

    display: flex;
    align-content: center;
}

.currentProportion.disabled {
    background-color: var(--color-disabled);
}

.label {
    margin: 0 auto;
    padding: var(--spacing-small);

    display: flex;
    align-items: center;

    text-align: center;
    font-size: var(--font-size-small)
}