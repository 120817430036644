.wrapper {
    isolation: isolate;
    font-size: var(--font-size-small);
}

.radio {
    margin: 0;
    position: relative;
    appearance: none;
    width: 20px;
    height: 20px;
    background-color: var(--color-gray-brighter);
    border-radius: 50%;
    cursor: pointer;
}

.label {
    display: flex;
    align-items: center;
    gap: var(--spacing-medium);
    
    cursor: pointer;
    user-select: none;
}

.label.disabled {
    cursor: default;
}

.labelText {
    padding-top: 3px;
}

.radio:disabled {
    cursor: default;
    background-color: var(--color-disabled);
}

.radio:checked {
    background-color: var(--color-mint);
}

.radio:checked:disabled {
    cursor: default;
    background-color: var(--color-disabled);
}

.radio::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 1px; /* push the black dot a tiny bit up to visually center it */
    left: 0;
    right: 0;
    margin: auto;
    width: 40%;
    height: 40%;
    background-color: black;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
}
