.history-panel ul li {
  border: 1px solid gray;
  margin: .2rem 0;
  padding: .25rem;
  user-select: none;
}

.history-panel ul li:hover:not(.active) {
  border: 1px solid #8ce699;
  cursor: pointer;
}

.history-panel .timestamp {
  text-align: right;
}

.history-panel ul li.active {
  background: #8ce699;
}
