.left, .right {
  position: fixed;
  display: flex;
  gap: var(--spacing-small);
  bottom: 0;
  padding: var(--spacing-xlarge);
  z-index: 20; /* the blazor content had 10 */
}

.left {
  left: 0;
}

.right {
  right: 0;
}
