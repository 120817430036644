.wrapper {
  width: 100%;
  height: 100%;
}

.placeholder {
  color: var(--color-gray-dark);
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  
  background-color: var(--color-gray-brightest);
  border-radius: var(--border-radius);
  outline: 1px dashed var(--color-gray-bright);
  outline-offset: -1px;
}

.img {
  object-fit: contain;
  object-position: left;
  width: 100%;
  height: 100%;
}
