.wrapper {
  width: 100%;
  max-width: 800px;
  user-select: none;
}

.aboveList {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: var(--spacing-standard);
  gap: var(--spacing-medium);
}

.filter {
  display: flex;
  gap: var(--spacing-medium);
  font-weight: 500;
}

.coverFallback {
  background-color: var(--color-forst);
}
