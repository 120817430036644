:root {
    /* breakpoints */
    --max-width-tablet: 768px;
    --max-width-mobile: 480px;
    --max-width-desktop: 1024px;
    --max-width-large: 1440px;

    /* colors */
    --color-white: #fff;
    --color-black: #000;

    --color-background: #ffffff;
    --color-background-secondary: #f0f0f0;
    --color-background-accent: #1e2343;
    --color-background-cancel: #d0d2d5;
    --color-background-cancel-hover: #d8d9dc;
    --color-warning: hsl(45deg 100% 50%);
    --color-danger: #fc2b6b;
    --color-danger-dark: #CE275E;
    --color-hint: var(--color-mint);
    --color-background-unit: #e9ecef;
    --color-cta: #8ce699;
    --color-cta-light: #e5fae8;
    --color-cta-dark: #79d97c;
    --color-cta-hover: #79d97c;
    --color-border: #dfdfdf;
    --color-border-dark: #707070;
    --color-secondary: #696969;
    --color-bounding-box: #50caff;

    /* ----------- style guide colors ------------- */
    --color-mint-brightest: #e5fae8;
    --color-mint-bright: #a9ebb3;
    --color-mint: hsl(129, 64%, 73%);
    --color-mint-dark: hsl(122, 56%, 66%);
    --color-mint-darker: hsl(133, 50%, 55%);

    --color-light: #ffee80;
    --color-light-bright: #fff9d6;
    --color-light-dark: #fadd6c;

    --color-universe: #1e2343;
    --color-universe-bright: #4d5584;

    --color-ocean: #bce1de;
    --color-ocean-bright: #dceff3;
    --color-ocean-dark: #99d3d8;

    --color-forst: #004141;

    --color-rubin: #fc2b6b;
    --color-rubin-dark: #ce275e;

    /* ----------- grays ------------- */
    --color-gray-brightest: hsl(225, 0%, 95%);
    --color-gray-brighter: hsl(225, 6%, 80%);
    --color-gray-bright: hsl(225, 6%, 70%);
    --color-gray: hsl(225, 6%, 60%);
    --color-gray-dark: hsl(225, 6%, 45%);
    --color-gray-darker: hsl(225, 6%, 30%);
    --color-gray-darkest: #878787;

    /* ----------- misc ------------- */

    --color-disabled: var(--color-gray-bright);

    --border-radius-small: 2px;
    --border-radius: 3px;
    --border-radius-forms: 4px;
    --border-radius-large: 6px;

    --box-shadow-color: rgba(0, 0, 0, 0.3);
    --box-shadow: 0 0 var(--spacing-medium) 0 var(--box-shadow-color);
    --box-shadow-small: 0 0 var(--spacing-small) 0 var(--box-shadow-color);

    --spacing-unit: 16px;
    --spacing-thin: calc(var(--spacing-unit) * 0.1);
    --spacing-small: calc(var(--spacing-unit) * 0.25);
    --spacing-medium: calc(var(--spacing-unit) * 0.5);
    --spacing-standard: calc(var(--spacing-unit) * 1);
    --spacing-large: calc(var(--spacing-unit) * 1.5);
    --spacing-xlarge: calc(var(--spacing-unit) * 2);
    --spacing-page-end: 112px;

    --line-height: 1.6;

    --font-size-normal: 1rem;
    --font-size-normal-bold: 0.9rem;
    --font-size-small: 0.8rem;
    --font-size-medium: 1.1rem;
    --font-size-large: 1.2rem;
    --font-size-larger: 1.5rem;
    --font-size-xlarge: 2rem;

    --input-height: 38px;
}
