.wrapper {
    background: var(--color-background);
    padding: var(--spacing-large);
    border-radius: var(--border-radius-large);
    transition: height 0.3s ease-in-out;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-large);
    max-width: 1050px;
    width: 100%;
}

.newWrapper {

}