.wrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-small);
    background-color: transparent;
    border: none;
    width: max-content;
    cursor: pointer;
    transition: opacity 150ms ease-in-out, background-color 250ms ease-in-out;
}

.wrapper .text {
    font-weight: 600;
}

.wrapper:hover {
    filter: brightness(0.92) saturate(1.3);
}

.wrapper:disabled:hover {
    filter: none;
}


.wrapper:disabled .iconWrapper {
    background-color: var(--color-gray-brighter);
    color: var(--color-gray-darker);
    cursor: not-allowed;
}

.wrapper:focus {
    outline: none;
}


.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--color-mint);
    border-radius: 50%;
}

.medium {
    font-size: var(--font-size-normal-bold);
    width: 40px;
    height: 40px;
}

.large {
    font-size: var(--font-size-large);
    width: 50px;
    height: 50px;
    box-shadow: var(--box-shadow);
}

.next {
    background-color: var(--color-light);
    width: 60px;
    height: 60px;
}

.next > span {
    padding-left: 4px; /* visually center the arrow */
}

.back {
    background-color: var(--color-white);
    width: 60px;
    height: 60px;
}

.back:hover {
    filter: brightness(0.85) saturate(1.3);
}

.back:disabled {
    background-color: var(--color-gray-brighter);
    cursor: not-allowed;
}

.help {
    background-color: var(--color-mint);
    width: 60px;
    height: 60px;
}

.save {
    background-color: var(--color-gray-brighter);
    width: 60px;
    height: 60px;
}
