.wrapper {
    position: relative;
    background-color: var(--color-background);
    width: 314px;
    height: 120px;
    padding: var(--spacing-standard) var(--spacing-large);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-medium);
    box-shadow: var(--box-shadow-small);
    border-radius: var(--border-radius);
    color: var(--color-black);
    isolation: isolate;
    border-left: 7px solid var(--color-mint);
}

.wrapper.disabled {
    border-left-color: var(--color-gray);
    cursor: default;
}

.close {
    position: absolute;
    padding: var(--spacing-medium);
    top: var(--spacing-small);
    right: var(--spacing-small);
    cursor: pointer;
    font-size: var(--font-size-small);
}

.edit {
    position: absolute;
    padding: var(--spacing-medium);
    bottom: var(--spacing-small);
    right: var(--spacing-small);
    cursor: pointer;
    font-size: var(--font-size-normal);
    color: black;
}

.wrapper.disabled .close:hover {
    transform: rotate(0deg);
}
