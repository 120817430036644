.wrapper {
    display: flex;
    height: 100%;
    width: 100%;
    max-width: 100vw;
}

.title {
    font-size: var(--font-size-large);
    padding-bottom: var(--spacing-xlarge);
}

.subtitle {
    font-size: var(--font-size-medium);
    padding-top: var(--spacing-xlarge);
    font-weight: 800;
}

.text {
    font-size: var(--font-size-medium);
}

.image {
    max-width: 60%;
    flex-grow: 1;
    object-fit: cover;
    object-position: top center;
}

.right {
    width: 40%;
    min-width: 450px;
    background-color: var(--color-white);
    padding: var(--spacing-xlarge);
    /* TODO: Remove once app is standalone */
    /* Needed as app is embedded into blazor app and there's a bottom bar which is positioned fixed
     * and overlaps the space
    */
    padding-bottom: calc(var(--spacing-xlarge) * 2);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}

.backButton {
    font-weight: 600;
}

.actionButtons {
    margin-top: auto;
    padding-top: var(--spacing-xlarge);
    display: flex;
    justify-content: space-between;
}

.wrapper .startButton {
    background-color: var(--color-light);
}

.wrapper .startButton:hover {
    background-color: var(--color-light-dark) !important;
}

@media (max-width: 768px) {
    .image {
        display: none;
    }

    .right {
        width: 100%;
    }
}
