.layers {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.layer {
  user-select: none;
  pointer-events: none;
}

.layer.text .text-content:focus {
  position: relative;
  z-index: 100;
  outline: 1px solid #0089b6;
  outline-offset: -1px;
}

.layer.image .image-reloader {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  font-size: 2rem;
  top: 50%;
  cursor: pointer;
  z-index: 10;
}
