.imageTile {
    background-color: var(--color-background);
    position: relative;
    width: 100%;
    border-radius: var(--border-radius-large);
    cursor: pointer;
    isolation: isolate;
    outline: 2px solid transparent;
    transition: outline 200ms;
    box-shadow: 0 3px 6px #00000029;
    aspect-ratio: 1 / 1;
    user-select: none;
}

.imageTile.disabled {
    cursor: default;
}

.imageTile:not(.disabled):hover, .imageTile.selected {
    outline: 2px solid var(--color-cta);
}

.imageTile:not(.selected, .disabled):hover .image {
    transform: scale(1.05);
}

.selectionCheckmark {
    position: absolute;
    left: var(--spacing-medium);
    top: var(--spacing-medium);
    color: var(--color-cta-dark);
    z-index: 10;
}

.imageWrapper {
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 80%;
    border-radius: var(--border-radius-large) 0 var(--border-radius-large) 0;
}

.imageTile.disabled:not(.selected) .imageWrapper {
    filter: grayscale(1);
}

.name {
    height: 20%;
    min-height: 50px;
    padding: var(--spacing-medium) var(--spacing-standard);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 500;
    font-size: var(--font-size-normal);
}

@media (max-width: 1199px) and  (min-width: 1100px) {
    .name {
        font-size: var(--font-size-small);
    }
}
