.designer-menu {
  display: flex;
  border-bottom: 1px solid lightgray;
}

.designer-menu .product-list {
  flex-grow: 1;
}

.designer-menu .menu-right {
  flex-shrink: 1;
  align-self: center;
  margin-right: .5rem;
}

.designer-menu .snap-control input {
  width: 1.5rem;
  text-align: center;
}

.designer-menu .zoom-control, .designer-menu .snap-control {
  display: inline-block;
  margin: 0 1rem;
}

.designer-menu .zoom-control button, .designer-menu .snap-control {
  min-width: 2rem;
  margin: 0 .5rem;
}

.designer-menu .product-list li {
  display: inline-block;
  background: white;
  padding: .5rem;
  user-select: none;
  cursor: pointer;
}

.designer-menu .product-list li.active {
  background: #8ce699;
  cursor: default;
}
