.wrapper {
    display: flex;
    flex-wrap: wrap;
    background-color: var(--color-gray-brightest);
    width: 100%;
}

.form {
    display: flex;
    justify-content: center;
    gap: var(--spacing-large);
    flex-wrap: wrap;
    background-color: var(--color-gray-brightest);
    flex-grow: 1;
}

.globalErrors {
    display: flex;
    justify-content: center;
    color: var(--color-danger);
    gap: var(--spacing-standard);
    margin-bottom: var(--spacing-xlarge);
}
