.sheet {
  box-sizing: content-box;
  display: inline-block;
  position: relative;
  transform-origin: top left;
  background: white;
  box-shadow: 4px 4px 10px #0003;
  margin: 80px 60px 60px;
}

.sheet-scaffold {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.sheet-scaffold::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  outline: 1px solid darkgrey;
}

.sheet-scaffold .bleed-line {
  position: absolute;
  display: block;
  outline: 2px dashed var(--color-danger-dark);
}

.sheet-scaffold .bleed-hint {
  position: absolute;
  left: 0;
  user-select: none;
  font-size: 0.7rem;
}

.sheet-scaffold .bleed-line-shadow {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  outline-color: #0002;
  outline-style: solid;
}

.sheet-scaffold, .sheet-scaffold * {
  pointer-events: none;
}

.sheet-scaffold .region-label {
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(-100%);
  white-space: nowrap;
  user-select: none;
}

.sheet-scaffold .region.JointBack .region-label, .sheet-scaffold .region.JointFront .region-label {
  left: 0;
  top: 50%;
  line-height: 100%;
  transform: translateX(-25%) rotate(270deg);
  /*
  FIXME: after rotation, height must be equal to joint width or else the text is not perfectly aligned
         but i think this must be done in JS
  */
}

.sheet-scaffold {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.sheet-scaffold .region.blocked {
  background: #0003;
}

.sheet-scaffold .region-text {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
  user-select: none;
}

.sheet-scaffold .region.Spine {
  outline: 1px solid lightgrey;
}

.sheet-scaffold .region.JointBack {
  border-left: 1px solid lightgrey;
  box-sizing: border-box;
}

.sheet-scaffold .region.JacketBack,
.sheet-scaffold .region.JointBack,
.sheet-scaffold .region.JointFront,
.sheet-scaffold .region.PageLeft {
  border-right: 1px solid lightgrey;
  box-sizing: border-box;
}

.sheet-scaffold .region.JointBack, .sheet-scaffold .region.JointFront {
  background: #fff3;
}

.sheet-scaffold .snap-line {
  position: absolute;
  outline-offset: -1px;
  opacity: .5;
}

.snap-line.horizontal {
  outline: 1px solid deeppink;
}

.snap-line.vertical {
  outline: 1px solid deeppink;
}
