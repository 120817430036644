.loader {
    z-index: 9999;
    border: 16px solid var(--color-gray-darker);
    border-radius: 50%;
    border-top: 16px solid var(--color-cta);
    width: 120px;
    height: 120px;
    animation: spin 1s linear infinite;
}

.wrapper {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9998;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
}

.backdrop {
    background-color: rgba(0, 0, 0, 0.5);
    animation: fadeIn 300ms ease-in-out;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}