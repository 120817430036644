.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
}

.imageWrapper {
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-medium)
}

.image {
    display: block;
    width: 120px;
    height: auto;
    box-shadow: var(--box-shadow);
    padding: var(--spacing-small);
}

.flex {
    display: flex;
    gap: var(--spacing-medium);
    align-items: center;
}
