.wrapper {
  display: flex;
  gap: var(--spacing-standard);
}

.editorOptionsWrapper {
  flex-grow: 1;
}

.addNew {
  display: flex;
  align-items: center;
  gap: var(--spacing-small);
  cursor: pointer;
  font-size: var(--font-size-medium);
  font-weight: 500;
  padding-top: var(--spacing-standard);
}

.addNew.disabled {
  cursor: not-allowed;
}