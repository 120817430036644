.datePicker {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
}

.datePicker.yearOnly :global .react-datepicker__input-container {
    max-width: 150px;
}

.datePicker.yearOnly :global .react-datepicker__input-container input {
    text-align: center;
}

.wrapper :global .react-datepicker__input-container input:disabled {
    border-color: var(--color-gray-bright);
}

.wrapper :global .react-datepicker__input-container input {
    height: var(--input-height);
    width: 100%;
    border: 1px solid var(--color-mint);
    box-shadow: none;
    border-radius: var(--border-radius-small);
    font-size: var(--font-size-normal);
    font-weight: 600;
    padding: var(--spacing-small) var(--spacing-medium);
}

.popper :global .react-datepicker {
    user-select: none;
    font-weight: 400;
    font-size: var(--font-size-small);
    border: 1px solid var(--color-border);
    border-radius: var(--border-radius);
}

.popper :global .react-datepicker__triangle {
    display: none;
}

.popper :global .react-datepicker__navigation:focus-visible {
    outline: 2px solid var(--color-cta);
}

.popper :global .react-datepicker__navigation-icon:before {
    border-color: #000;
    top: 7px;
}

.popper :global .react-datepicker__navigation {
    top: 3px;
    border: none;
    color: black;
    padding: var(--spacing-medium) var(--spacing-standard);
    border-radius: var(--border-radius-small);
    font-weight: 600;
    font-size: var(--font-size-normal-bold);
    transition: 0.15s ease-in-out;
}

.popper :global .react-datepicker__navigation:hover {
    opacity: 0.6;
}

.popper :global .react-datepicker__header {
    background-color: var(--color-mint-brightest);
    border-radius: 0;
}

.popper :global .react-datepicker__year-wrapper {
    user-select: none;
    font-size: var(--font-size-small);
    justify-content: space-between;
}

.popper :global .react-datepicker__year .react-datepicker__year-text {
    font-size: var(--font-size-small);
    border-radius: var(--border-radius);
    margin: var(--spacing-small);
    padding: var(--spacing-small)
}

.popper :global .react-datepicker__year-text:hover,
.popper :global .react-datepicker__day:hover {
    background-color: var(--color-mint-bright);
}

.popper :global .react-datepicker__year-text--selected,
.popper :global .react-datepicker__day--selected {
    background-color: var(--color-cta);
    border-radius: var(--border-radius);
}

.popper :global .react-datepicker__year-text:focus-visible,
.popper :global .react-datepicker__day:focus-visible {
    outline: 2px solid var(--color-cta);
}

.popper :global .react-datepicker__year-text--keyboard-selected,
.popper :global .react-datepicker__day--keyboard-selected {
    background-color: var(--color-cta);
    border-radius: var(--border-radius);
}
