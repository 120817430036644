.color-picker .pointer {
  display: grid;
  grid-template-columns: auto min-content min-content;
  margin-bottom: .5rem;
  justify-content: left;
}

.saturation-value-area {
  position: relative;
  width: 256px;
  height: 256px;
  user-select: none;
  border: 1px solid #bbb;
  box-sizing: border-box;
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0)),
  linear-gradient(to right, #fff, rgba(255, 255, 255, 0)),
  linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.saturation-value-area .saturation, .saturation-value-area .saturation * {
  width: 100%;
  height: 100%;
}

.saturation-value-area .indicator {
  position: absolute;
  border-radius: 50%;
  border: 1px solid white;
  width: 10px;
  height: 10px;
  transform: translate(-50%, 50%);
  mix-blend-mode: difference;
}

.saturation-white {
  background: linear-gradient(to right, #fff, rgba(255, 255, 255, 0));
}

.saturation-black {
  background: linear-gradient(to top, black, rgba(255, 255, 255, 0));
}

.hue-slider {
  position: relative;
  user-select: none;
  width: 2rem;
  border: 1px solid #bbb;
  margin-left: .5rem;
  background: linear-gradient(to bottom, #f00 0%, #ff0 16.6667%, #0f0 33.3333%, #0ff 50%, #00f 66.6667%, #f0f 83.3333%, #f00 100%);
}

.alpha-slider {
  position: relative;
  user-select: none;
  width: 1rem;
  border: 1px solid #bbb;
  margin-left: 1rem;
}

.alpha-slider .alpha-gradient {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.hue-slider .indicator, .alpha-slider .indicator {
  box-sizing: content-box;
  position: relative;
  left: 0;
  width: 100%;
  height: 0;
  margin-top: -9px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid gray;
}

.color-input .hex input {
  width: 5rem;
  margin: .25rem .5rem;
}

.color-input .hsv input, .color-input .rgba input, .color-input .cmyk input {
  width: 3rem;
  margin: .25rem .5rem;
}

.color-picker .suggested-colors-separator {
  border: 0;
  border-bottom: 1px solid #ccc;
  text-align: center;
  position: relative;
  margin: 1.2rem 0;
}

.color-picker .suggested-colors-separator span {
  position: absolute;
  transform: translate(-50%, -50%);
  padding: 0 .5rem;
  background: white;
  user-select: none;
}

.color-picker .suggested-colors {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto auto auto auto;
  justify-content: center;
}

.color-picker .suggested-colors .color-display {
  position: relative;
  width: 1.8rem;
  height: 1.8rem;
  display: inline-block;
  outline: 0;
  transition: transform ease .2s;
}

/* add a white border around the white color display so it can be distinguished from the background */
.color-picker .suggested-colors .color-display:first-child, .color-picker .suggested-colors .color-display:nth-child(9) {
  outline: 1px solid #ccc;
  outline-offset: -1px;
}

.color-picker .suggested-colors .color-display:hover {
  cursor: pointer;
  outline: 2px solid #8ce699;
  outline-offset: 0;
  transform: scale(1.2);
  z-index: 1;
}

.color-picker .palette {
  text-align: center;
}

.color-picker .palette .color-display {
  border-radius: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  outline-width: 1px;
}

.color-picker .palette .color-display.selected {
  outline: 3px solid #8ce699;
  position: relative;
  z-index: 1;
}

.color-picker footer {
  text-align: center;
  margin-top: 1rem;
}

.color-picker footer button:first-child {
  margin-right: 1rem;
}
