.container {
    display: grid;
    width: 100%;
    gap: var(--spacing-xlarge);
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

@media (max-width: 1099px) {
    .container {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 700px) {
    .container {
        grid-template-columns: 1fr;
    }
}

.element {
    display: flex;
}
