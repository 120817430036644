.template-list {
  overflow-y: auto;
  display: block;
  height: 100%;
  transition: opacity ease 250ms;
}

.template-list.busy {
  opacity: .5;
}

.template-list li {
  display: inline-block;
  cursor: pointer;
}

.template-list li:hover, .template-list li.active {
  outline: 1px solid #8ce699;
}

.template-list li img {
  width: 100%;
  height: auto;
}
