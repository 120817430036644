.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
    align-items: center;
}

.input {
    height: 100%;
    width: 150px;
    appearance: textfield;
    border: 1px solid var(--color-mint);
    border-radius: var(--border-radius-forms);
    padding: var(--spacing-small) var(--spacing-medium);
    min-height: var(--input-height);
}

.input:disabled {
    border: 1px solid var(--color-disabled) !important;
}

.withAddendum {
    border-radius: 0;
}

.addendum {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    font-size: var(--font-size-small);
    background-color: var(--color-gray-brightest);
    border: 1px solid var(--color-gray-brighter);
    border-left: none;
    color: var(--color-gray);
    width: max-content;
    padding: var(--spacing-small) var(--spacing-medium);
    border-radius: 0 var(--spacing-small) var(--spacing-small) 0;
    user-select: none;
    min-height: var(--input-height);
}

.inputWrapper {
    display: flex;
    align-items: center;
}

.labels {
    padding-left: var(--spacing-thin); /* visually align with input */
}

.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0; /* Apparently some margin are still there even though it's hidden */
  }

.input[type='number'] {
      -moz-appearance: textfield; /* Firefox */
      font-weight: 600;
      min-width: 4ch;
      border: 1px solid var(--color-cta);
      text-align: right;
  }

.input:focus {
      background-color: var(--color-cta-light);
      outline: none;
  }

.error.input[type='number'] {
      border-color: var(--color-rubin-dark);
  }
