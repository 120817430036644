.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium);
}

.withMarker {
    border-left: var(--spacing-medium) solid var(--color-mint);
    padding-left: var(--spacing-standard);
    padding-bottom: var(--spacing-medium);
}

.heading {
    font-size: var(--font-size-large);
    font-weight: 500;
    margin: 0;
}

.button {
    background-color: transparent;
    border: none;
    display: flex;
    gap: var(--spacing-large);
    align-items: center;
    cursor: pointer;
    padding: 0;
}

.button > span {
    padding-top: 4px;
}
