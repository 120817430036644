.select {
  min-width: 10rem;
}

.wrapper {
  width: 46rem;
  height: 26rem;
}

.container {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-large);
}

.actionBar {
  display: flex;
  justify-content: space-between;
  padding-bottom: var(--spacing-large);
}

.flex {
  display: flex;
  gap: var(--spacing-standard);
  flex-direction: column;
}

.address {
  display: flex;
  gap: var(--spacing-small);
  align-items: flex-end;
}

.address > div  {
  flex: 4 4 120px;
}

.address > div:first-child {
  flex: 1 1 120px;
}
