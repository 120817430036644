.wrapper {
    display: flex;
    isolation: isolate;
    position: relative;
    height: 40px;
    width: 300px;
}
.wrapper.disabled {
    cursor: not-allowed;
}

.wrapper.disabled .option.active {
    background-color: var(--color-gray-brightest);
    border-color: var(--color-gray-bright);
    box-shadow: none;
}

.wrapper.disabled .option {
    background-color: var(--color-white);
    cursor: not-allowed;
}

.wrapper.invert {
    flex-direction: column-reverse;
}

.toggleButton {
    display: none;
}

.option {
    display: flex;
    position: absolute;
    bottom: 0;
    height: 40px;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-gray-brighter);
    width: 150px;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.2s ease-in-out;
    font-size: var(--font-size-normal);
    font-weight: 500;
}

.left {
    left: 0;
}

.right {
    right: 0;
}

.labelStyle {
    font-size: var(--font-size-small);
    font-weight: 400;
}

.active {
    background-color: var(--color-mint-brightest);
    box-shadow: 0 0 0 1px var(--color-mint-bright);
    border-color: var(--color-mint-bright);
    pointer-events: none;
}
