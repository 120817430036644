.navGroup {
  list-style-type: none;
  counter-increment: menu;
  user-select: none;
}

.navGroup:after {
  display: flex;
  content: "";
  height: 1px;
  background-color: var(--color-ocean);
  width: calc(100% - 2 * var(--spacing-medium));
  margin: 3px auto 0;
}

.navGroup.disabled .groupName {
  cursor: default;
  color: var(--color-gray-bright);
}

.groupName {
  display: inline-block;
  cursor: pointer;
  font-weight: 600;
  padding: var(--spacing-standard) var(--spacing-medium);
  font-size: var(--font-size-normal-bold);
  width: 100%;
}

.groupName:before {
  content: counter(menu) ". "
}

.subMenu {
  padding: 0;
}
