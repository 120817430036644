.color-display {
  width: 5rem;
  height: 5rem;
  outline: 2px solid #ddd;
  border-radius: 50%;
  overflow: hidden;
}

.color-display .color-fill {
  display: block;
  width: 100%;
  height: 100%;
}

.fill-checkerboard {
  background: rgba(0, 0, 0, 0) url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAK0lEQVQ4y2P8////fwY84OTJk/ikGZgYKASjBgwGA1gIxbO5ufloIA5/AwCvtQl5itE62AAAAABJRU5ErkJggg==") repeat scroll left center;
}
