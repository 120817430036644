.label {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-small);
}

.input {
    position: relative;
    border: 1px solid var(--color-mint);
    box-shadow: none;
    border-radius: var(--border-radius-forms);
    font-size: var(--font-size-normal);
    font-weight: 600;
    padding: var(--spacing-small) var(--spacing-medium);
    min-height: var(--input-height);
}

.input:disabled {
    background-color: var(--color-gray-brightest);
    border: 1px solid var(--color-gray-bright);
}

input:focus {
    outline: none;
    background-color: var(--color-mint-brightest);

}

.error.input {
    border-color: var(--color-rubin-dark);
}

.eye {
    position: absolute;
    right: var(--spacing-small);
    bottom: 3px;
    cursor: pointer;
    color: var(--color-gray-dark);
}
