.wrapper {
    display: flex;
    position: relative;
    background: #f2f2f2;
    height: 100%;
}

.faqButton {
    position: fixed;
    left: var(--spacing-xlarge);
    bottom: var(--spacing-xlarge);
}
