.wrapper {
    display: grid;
    justify-items: center;
    gap: var(--spacing-standard);
    max-width: 1000px;
    width: 100%;
}

.control {
    display: flex;
    align-items: center;
    background-color: var(--color-background);
    padding: var(--spacing-standard) var(--spacing-large);
    border-radius: var(--border-radius);
    gap: var(--spacing-standard);
    width: 100%;
}

.text {
    margin-right: auto;
}

.container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing-large);
}


.roleGroup {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
    padding-bottom: var(--spacing-xlarge);
    flex-grow: 1;

}

.flex {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-standard);
    align-items: center;
}
