.wrapper {
  padding-top: 57px;
  display: flex;
  gap: var(--spacing-xlarge)
}

.currentProjects {
    padding-top: var(--spacing-xlarge);
}

.headline {
    padding-bottom: var(--spacing-standard);
}

.loginBox {
  display: flex;
  flex-direction: column;
  background-color: #004141;
  color: #8ce699;
  width: 30%;
  min-width: 200px;
  max-width: 350px;
  height: 70vh;
}

.loginBox .header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 120px;
  padding: var(--spacing-standard);
}

.loginBox .image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content {

}

.button {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 1;
}

.navigation {
  display: flex;
  gap: var(--spacing-standard);
}

.iconWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #8ce699;
    color: #004141;
  padding: var(--spacing-xlarge);
}