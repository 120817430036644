.format {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
    width: 100%;
}

.radioGroup {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-medium)
}

.sizeSelection {
    min-height: 70px;
    display: flex;
    align-items: center;
    width: 100%;
}

.selectWrapper {
    width: 100%;
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: var(--spacing-small);
    width: 210px;
}

.buttons input[type=number] {
    font-size: var(--font-size-normal);
}

.icon {
    font-size: 0.6rem;
    position: relative;
    transform-origin: bottom center;
    transform: translateY(calc(var(--spacing-standard) * -1 + 2px)); /* align that icon -.- */
}
