.wrapper {
    width: calc(220px + var(--spacing-large) * 2);
    transition: filter 500ms, background-color 500ms;
    position: relative;
    isolation: isolate;
    display: flex;
    flex-direction: column;
}

.content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 0 var(--spacing-large) var(--spacing-large);
}

.headerButton {
    background-color: transparent;
    border: none;
    padding: var(--spacing-standard);
    cursor: pointer;
    width: 100%;
    height: 100%;
}

.headerButton:disabled {
    cursor: not-allowed;
    color: var(--color-black)
}

.wrapper.disabled .header {
    background-color: var(--color-gray);
}

.wrapper.bookUpdate .header {
    background-color: var(--color-gray);
}

.wrapper.disabled .image {
    filter: grayscale(1) brightness(1.1);
}

.row {
    border-bottom: 1px solid var(--color-border);
    background-color: var(--color-white);
    border-radius: var(--border-radius-large);
}

.header {
    border-top-left-radius: var(--border-radius-large);
    border-top-right-radius: var(--border-radius-large);
    background-color: var(--color-mint);
    text-align: center;
    font-weight: 600;
}

.footer {
    margin-top: var(--spacing-standard);
    padding: var(--spacing-standard) 0;
    border-top: 1px solid var(--color-border);
    display: flex;
    justify-content: center;
}

.image {
    width: auto;
    height: 100px;
    filter: brightness(1.1);
}

.checkbox {
    position: absolute;
    top: 10px;
    left: var(--spacing-standard);
}

.imageWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: var(--spacing-large);
}
