.wrapper {
    position: relative;
    display: flex;
    align-items: center;
    gap: var(--spacing-small);

    width: 24px;
    height: 24px;
}

.button {
    cursor: help;
    background-color: transparent;
    border: none;
}

.i {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--color-warning);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    aspect-ratio: 1/1;
    padding-left: 1px; /* visually center the i */
    color: var(--color-warning);
    font-weight: 600;
    clip-path: polygon(0% 0%, 100% 0%, 100% 67%, 73% 53%, 66% 68%, 100% 87%, 100% 100%, 0% 99%);;
}

.infoBox {
    min-width: 300px;
    border-radius: var(--border-radius);
    font-size: var(--font-size-small);
    font-weight: 400;
    box-shadow: var(--box-shadow);
    padding: var(--spacing-large);
    background-color: var(--color-background);
    transition: opacity 0.3s ease-in-out;
}

.wrapper:hover > .infoBox {
    display: revert;
}
