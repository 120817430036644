.wrapper {
  --bg-color: var(--color-mint);
  --color: var(--color-black);
  background-color: var(--bg-color);
  color: var(--color);
  padding: var(--spacing-small) var(--spacing-medium);
  border-radius: var(--border-radius);
  font-weight: 500;
  font-size: var(--font-size-small);
  width: max-content;
}
