.wrapper {
    display: flex;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    background-color: var(--color-white);
    height: 57px;
    align-items: center;
    border-bottom: 1px solid #dfdfdf;
    user-select: none;
}

.menuButton {
    padding-left: var(--spacing-large);
}

.menu {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 285px;
    background-color: #1E2343;
    transition: opacity 0.5s ease-in-out, left 0.5s ease-in-out;
}

.menu.close {
    opacity: 0;
    pointer-events: none;
    left: -285px;
}

.closeWrapper {
    display: flex;
    justify-content: flex-end;
    padding: var(--spacing-large);
}

.closeButton {
    color: var(--color-white);
}

.wrapper .nav {
    display: flex;
    flex-direction: column;
    padding-top: var(--spacing-xlarge);
    overflow-y: auto;
}

.navItem a {
    display: flex;
    gap: var(--spacing-standard);
    color: white;
    text-decoration: none;
    padding: var(--spacing-standard);
    padding-left: var(--spacing-xlarge);
}

.navItem a:hover {
    background-color: #79d97c;
    color: #000;
}

.navItem a:hover .icon {
    color: #000;
}

.icon {
    color: #8ce699;
    font-size: 20px;
}

.left {
    margin-top: -3px;
}

.location {
    font-weight: 500;
    padding: 0 var(--spacing-medium);
}

.logoWrapper {
    margin: 0 auto;
}

.logo {
    width: auto;
    height: 42px;
}

.icons {
    display: flex;
    padding-right: var(--spacing-large);
    gap: var(--spacing-medium);
}

.newNotification {
    position: relative;
}

.newNotification:after {
    position: absolute;
    width: 5px;
    height: 5px;
    border-radius: 100%;
    content: '';
    display: block;
    background-color: red;
    top: 0;
    right: 3px;
}