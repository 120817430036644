.cover-preview {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100;
}

.cover-preview-backdrop {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: #0008;
}

.cover-preview .bounding-box {
  display: none;
}

.cover-preview .book {
  position: absolute;
  transform-style: preserve-3d;
  transform-origin: center;
  cursor: move;
  left: 50%;
  top: 50%;
}

.cover-preview .book > div {
  position: absolute;
  box-shadow: 0 0 3px #0005;
  overflow: hidden;
  pointer-events: none;
  background: white;
}

.cover-preview .book > .book-top,
.cover-preview .book > .book-bottom,
.cover-preview .book > .book-front {
  box-shadow: inset 0 0 3px #0005;
}