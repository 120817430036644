.menu {
  background: white;
  border: 1px solid gray;
  box-shadow: 0 3px 3px #0005;
  display: inline-block;
}

.menu .menu-item {
  position: relative;
  display: inline-block;
  user-select: none;
}

/*.menu .menu-item button {*/
/*  min-width: 2rem;*/
/*  min-height: 2rem;*/
/*  padding: .2rem;*/
/*  font-size: 1.2rem;*/
/*  border: 0;*/
/*  background: transparent;*/
/*}*/

.menu .menu-item button:hover {
  background: #CCFFD6FF;
}

.menu .menu-item button:active {
  background: #5bac66;
}

.menu .sub-menu-arrow {
  margin-left: .2rem;
  pointer-events: none;
}

.menu .sub-menu {
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translate(-50%);
  width: max-content;
}
