.layer-thumbnail {
  display: grid;
  width: 48px;
  height: 48px;
  outline: 1px solid lightgray;
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.layer-thumbnail > .tredition-icon {
  justify-content: center;
}

.layer-thumbnail .texture {
  width: inherit;
  height: inherit;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #eee;
}
