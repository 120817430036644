.wrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-large);
}

.vertical {
    flex-direction: column;
    gap: var(--spacing-medium);
    align-items: flex-start;
}
