.wrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-medium);
    padding: var(--spacing-medium) var(--spacing-standard);
    border-radius: var(--border-radius);
    color: white;
    font-weight: 500;
    background-color: var(--color-rubin-dark);
    font-size: var(--font-size-normal);
}
