.wrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-small);
    background-color: transparent;
    border: none;
    width: max-content;
    cursor: pointer;
    font-weight: 600;
}

.wrapper.disabled {
    cursor: not-allowed;
}

.wrapper.medium {
    font-size: var(--font-size-normal-bold);
}

.wrapper.large {
    font-size: var(--font-size-large);
}

.wrapper.xlarge {
    font-size: var(--font-size-xlarge);
}
