.wrapper {
    border: none;
    padding: var(--spacing-medium) var(--spacing-standard);
    border-radius: var(--border-radius-small);
    font-weight: 600;
    font-size: var(--font-size-normal-bold);
    transition: 0.15s ease-in-out;
    cursor: pointer;
    min-width: 150px;
}

.wrapper.primary {
    background-color: var(--color-cta);
    color: black;
}

.wrapper.danger {
    background-color: var(--color-rubin-dark);
    color: white;
}

.wrapper.danger:hover {
    background-color: var(--color-rubin);
}

.wrapper.danger:enabled:active {
    background-color: var(--color-rubin);
    opacity: 0.9;
}

.wrapper.secondary {
    background-color: white;
    color: black;
    border: 2px solid var(--color-border);
    padding: calc(var(--spacing-medium) - 2px) calc(var(--spacing-standard) - 2px);
    transition: border 200ms ease-in-out;

}

.wrapper.link {
    background-color: white;
    color: black;
    min-width: max-content;
}

.wrapper.link > span {
    position: relative;
    z-index: 2;
}

.wrapper.link:enabled:active {
    background-color: var(--color-white);
}

.wrapper.link:after {
    opacity: 0.7;
    display: block;
    position: relative;
    bottom: 5px;
    content: "";
    height: 5px;
    margin: auto;
    transition: width 100ms ease-in-out;
    background-color: var(--color-gray-brighter);
}

.wrapper.link:hover:after {
    background-color: var(--color-gray-bright);
}

.wrapper.secondary.active {
    background-color: var(--color-cta);
}

.wrapper.secondary:enabled:hover {
    border: 2px solid var(--color-cta);
}

.wrapper.secondary.active {
    background-color: var(--color-cta);
}

.wrapper.secondary:enabled:hover {
    border: 2px solid var(--color-cta);
}

.wrapper.primary:enabled:hover {
    background-color: #79d97c;
}

.small {
    width: 200px;
    height: 38px;
    font-weight: 600;
}

.medium {
    width: max-content;
}

.wide {
    padding: var(--spacing-medium) 60px;
    width: max-content;
}

.large {
    width: 100%;
    font-size: var(--font-size-large);
    padding: var(--spacing-standard);
}

.wrapper:disabled {
    background-color: var(--color-gray-brightest);
    color: var(--color-gray-darker);
    cursor: not-allowed;
    opacity: .65;
}

.wrapper:enabled:active {
    background-color: var(--color-mint-darker)
}
