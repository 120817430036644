.bounding-box {
  display: block;
  position: absolute;
  user-select: none;
  outline-offset: -1px; /* causes an `inset` so the bounding box border 100% matches the content dimensions and is not outside it. must be equal to outline width */
}

.bounding-box:not(.active):hover {
  outline: 1px dashed #50caff;
  cursor: pointer;
}

.bounding-box.active {
  outline: 1px dashed var(--color-bounding-box, #50caff);
  cursor: move;
}

.bounding-box.problem-warning {
  outline: 1px solid orange;
}

.error .bounding-box {
  outline-color: red;
}

.bounding-box-handle, .rotation-handle {
  display: block;
  position: absolute;
  border: .1rem solid white;
  border-radius: 50%;
  box-sizing: border-box;
  background: var(--color-bounding-box, #50caff);
}

.bounding-box .problem {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  outline: 1px solid orange;
}

.bounding-box .problem > .tredition-icon {
  background: orange;
  color: #552200;
  padding: 0.1rem 0.2rem;
  cursor: help;
  display: inline;
}

.rotation-handle {
  cursor: alias;
}

.rotation-handle::after {
  display: block;
  position: relative;
  content: '';
  left: -50px;
  top: 50%;
  width: 50px;
  height: 0;
  border: 1px dashed var(--color-bounding-box, #50caff);
  transform: translateY(-50%);
}

.error .bounding-box-handle {
  background: red;
}
