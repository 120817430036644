.context-menu-bar {
  position: absolute;
  background: white;
  border: 1px solid gray;
  box-shadow: 3px 3px 3px #0003;
}

.context-menu {
  padding: .2rem;
}

.context-menu .layer-label {
  margin-right: 1rem;
  margin-left: .5rem;
}

.context-menu .font-size input, .context-menu .line-height input, .context-menu .letter-spacing input {
  width: 3rem;
}

.context-menu .menu-item.active button {
  background: #8ce699;
}

.context-menu .menu-item.mixed button {
  background: repeating-linear-gradient(135deg, #8ce699, #8ce699 10px, white 10px, white 20px)
}

.context-menu li:first-child {
  margin-left: 0;
}

.context-menu li:hover {
  border-color: #5bac66;
  cursor: pointer;
}

.context-menu li:active {
  background: darkgray;
  cursor: pointer;
}

.context-menu .color {
  position: relative;
}

.context-menu .color .indicator {
  position: absolute;
  left: -20%;
  bottom: -3px;
  width: 140%;
  height: 3px;
  border: 1px solid black;
}

.context-menu .alignment {
  position: relative;
}

.context-menu .alignment-popup {
  position: absolute;
  background: white;
  padding: .2rem;
  border: 1px solid darkgray;
  box-shadow: 0 0 3px #0008;
  left: 50%;
  top: 100%;
  transform: translate(-50%);
}
