.navItem {
  position: relative;
  transition: background-color 100ms;
  list-style-type: none;
  cursor: pointer;
  padding: var(--spacing-medium);
  padding-left: var(--spacing-xlarge);
  font-size: var(--font-size-small);
  isolation: isolate;
  user-select: none;
}

.navItem.disabled {
  cursor: default;
  color: var(--color-gray-bright);
}

.icon {
  position: absolute;
  left: var(--spacing-medium);
  top: 50%;
  transform: translateY(-50%);
}

.iconComplete {
  color: var(--color-mint-dark);
}

.iconWarning {
  color: var(--color-danger);
}

.navItem:not(.disabled):hover,
.navItem.active:not(.disabled):hover {
  font-weight: 600;
  background-color: var(--color-light-bright);
}

.navItem.active {
  font-weight: 600;
  background-color: var(--color-light);
}