.palette-list {
  margin-top: 2rem;
}

.palette-list li {
  margin-bottom: 2rem;
  line-height: 0;
  cursor: pointer;
}

.palette-list input[type="checkbox"] {
  pointer-events: none;
  margin: 0 .5rem;
}

.palette-list li .palette {
  display: inline-block;
  vertical-align: middle;
}

.palette-list li.active .palette, .palette-list li:hover .palette {
  outline: 2px solid #8ce699;
  box-shadow: 0 0 5px #8ce699;
}

.palette-list .palette-choice {
  display: inline-block;
}

.palette-list .palette .color-display {
  border-radius: 0;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  outline-width: 1px;
}

.palette-list .palette-operations {
  display: block;
  margin-top: .2rem;
}

.palette-list .palette-operations button {
  margin-right: .2rem;
}
