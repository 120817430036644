.designer {
  display: grid;
  grid-template-columns: auto min-content;
  grid-template-rows: auto auto;
}

.designer-header {
  position: sticky;
  top: 0;
  z-index: 200;
  background: white;
}

.designer-main {
  position: relative;
  /*
  we open a new stacking context for the designer so we can have infinite layer depth values going in either direction.
  all other designer siblings should have have z-index > 0 for this to work properly
  */
  z-index: 0;
  overflow: hidden;

  grid-area: 1 / 1 / span 1 / span 1;
}

.designer .workspaces {
  overflow-y: auto;
  max-height: calc(100vh - 95px); /* needs to be adjusted if something else is above the container like the main header menu */
}

.designer .dropzone {
  position: absolute;
  display: block;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.designer .dropzone.active {
  box-shadow: inset 0 0 .2rem lime;
  cursor: copy;
  z-index: 100;
}
