.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: var(--spacing-xlarge);
    align-items: stretch;
    max-width: 1000px;
    width: 100%;
}

@media (max-width: 1099px) {
    .wrapper {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 700px) {
    .wrapper {
        grid-template-columns: 1fr;
    }
}

.buttonWrapper {
    display: flex;
    gap: var(--spacing-xlarge);
    margin-bottom: var(--spacing-xlarge);
}

.headline {
    font-size: var(--font-size-large);
    font-weight: 500;
    text-align: center;
    padding-top: var(--spacing-standard);
    grid-column: 1 / -1;
}

.tileContainer {
    grid-column: 1 / -1;
}

.wrapper .genreButton {
    min-height: 70px;
    border-color: transparent;
    border-radius: var(--border-radius-large);
}
