.list {
    list-style: none;
    padding: 0 var(--spacing-standard) 0 0;
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
    overflow: auto;
    max-height: 50vh;
    width: 800px;
}

.text {
    font-weight: 500;
    font-size: var(--font-size-normal);
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 500px;
    align-items: center;
}

.text h4 {
    font-size: var(--font-size-normal);
    font-weight: 500;
    margin: 0;
}

.role {
    color: var(--color-gray-dark)
}
