.label {
    margin-bottom: var(--spacing-small);
    display: block;
}

.select {
    font-weight: 600;
}

.select.hasError [class$="-control"] {
    border: 1px solid var(--color-rubin) !important;
}

.select [class$="-menu"] {
    font-weight: 400;
    font-size: var(--font-size-small);
}

.select [class$="-control"] {
    border: 1px solid var(--color-cta);
    box-shadow: none;
}

.select[class*="--is-disabled"] [class$="-control"] {
    border: 1px solid var(--color-disabled);
}

.select [class$="-control"]:hover {
    border: 1px solid var(--color-cta);
    box-shadow: 0 0 0 1px var(--color-cta);
}

.select [class*="control--is-focused"] {
    box-shadow: 0 0 0 1px var(--color-cta);
}

.select [class*="option--is-focused"], .select [class*="option--is-selected"] {
    background-color: var(--color-mint-bright);
    color: #000;
}

.select [class*="option"] {
    min-height: 2rem;
}

.select [class*="option"]:active {
    background-color: var(--color-mint);
}

.select [class*="-is-disabled"] [class*="-multiValue"] {
    background-color: var(--color-gray-bright);
}

.select [class*="-multiValue"] {
    background-color: var(--color-mint-brightest);
    user-select: none;
}

.select [class*="multi-value__remove"]:hover {
    background-color: var(--color-mint-bright);
    color: #000;
}
