.wrapper {
    background-color: var(--color-background);
    width: 100%;
    height: 100%;
    border-radius: var(--border-radius-large);
    display: flex;
}

.section {
    flex: 0 0 420px;
    padding: var(--spacing-xlarge);
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: var(--spacing-standard);
}

.wrapper .section > h1 {
    margin-bottom: var(--spacing-large);
}

.flex {
    display: flex;
    align-items: center;
    gap: var(--spacing-medium);
}

.button {
    margin-top: var(--spacing-large);
    align-self: flex-end;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 0 var(--border-radius-large) var(--border-radius-large) 0;
 }
