.wrapper {
    font-size: var(--font-size-normal);
    font-weight: 600;
    color: var(--color-black);
    text-decoration: none;
    position: relative;
    isolation: isolate;
    width: max-content;
}

.wrapper:hover {
    color: revert;
}

.underline:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 30%;
    width: 100%;
    background-color: var(--color-mint);
    z-index: -1;
    transition: 0.15s ease-in-out;
}

.underline:hover:before {
    background-color: var(--color-mint-darker);
}

