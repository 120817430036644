.wrapper {
    padding: 0;
    position: relative;
    background-color: transparent;
    border: none;
    color: black;
    font-weight: 600;
    font-size: 2.5rem;
    cursor: pointer;
    isolation: isolate;
    width: max-content;
}

.wrapper:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    height: 46%;
    width: 100%;
    background-color: var(--color-light);
    z-index: -1;
    transition: 0.15s ease-in-out;
}

.wrapper:hover:before {
    background-color: var(--color-light-dark);
}

