@font-face {
  font-family: "tredition";
  src: url("./tredition.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

.tredition-icon:before {
  font-family: "tredition" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.rotate-icon {
  transform: rotate(90deg);
}

.tredition-icon {
  font-size: inherit;
  display: flex;
  align-items: center;
}

.tredition-icon.small {
  font-size: .5rem;
}

.tredition-icon.large {
  font-size: 2rem;
}

.ti-3d-ansicht:before {
  content: "\61";
}

.ti-3d-ansicht2:before {
  content: "\62";
}

.ti-3d-ansicht-control:before {
  content: "\63";
}

.ti-abstand-nach-absatz:before {
  content: "\64";
}

.ti-abstand-nach-ueberschrift:before {
  content: "\65";
}

.ti-acht:before {
  content: "\66";
}

.ti-achtung:before {
  content: "\67";
}

.ti-achtung-control:before {
  content: "\68";
}

.ti-affiliate-programm:before {
  content: "\69";
}

.ti-aktualisieren:before {
  content: "\6a";
}

.ti-aktualisieren-control:before {
  content: "\6b";
}

.ti-ansehen-control:before {
  content: "\6c";
}

.ti-ansehen-vorschau:before {
  content: "\6d";
}

.ti-ansprechpartner-mitarbeiten:before {
  content: "\6e";
}

.ti-app-switch:before {
  content: "\6f";
}

.ti-audiobuch:before {
  content: "\70";
}

.ti-ausrichtenmitte-control:before {
  content: "\71";
}

.ti-ausrichtenoben-control:before {
  content: "\72";
}

.ti-ausrichtenunten-control:before {
  content: "\73";
}

.ti-auto-satz:before {
  content: "\74";
}

.ti-autor:before {
  content: "\75";
}

.ti-avatar:before {
  content: "\76";
}

.ti-avatar-control:before {
  content: "\77";
}

.ti-barcode:before {
  content: "\78";
}

.ti-barcode-control:before {
  content: "\79";
}

.ti-berichte:before {
  content: "\7a";
}

.ti-bilder:before {
  content: "\41";
}

.ti-blocksatz-control:before {
  content: "\42";
}

.ti-bold-control:before {
  content: "\43";
}

.ti-buch:before {
  content: "\44";
}

.ti-bucherfolg:before {
  content: "\45";
}

.ti-buchgestaltung-projekt:before {
  content: "\46";
}

.ti-buchhandel:before {
  content: "\47";
}

.ti-buchmarketing:before {
  content: "\48";
}

.ti-buchproduktion:before {
  content: "\49";
}

.ti-buchpruefung:before {
  content: "\4a";
}

.ti-buchtypen:before {
  content: "\4b";
}

.ti-buchvertrieb:before {
  content: "\4c";
}

.ti-chat:before {
  content: "\4d";
}

.ti-check-control:before {
  content: "\4e";
}

.ti-cloud:before {
  content: "\4f";
}

.ti-coverdesigner:before {
  content: "\50";
}

.ti-dashboard:before {
  content: "\51";
}

.ti-diagrammansicht-control:before {
  content: "\52";
}

.ti-diagrammansicht-kreis:before {
  content: "\53";
}

.ti-dokument:before {
  content: "\54";
}

.ti-download1:before {
  content: "\55";
}

.ti-download-control:before {
  content: "\56";
}

.ti-drei:before {
  content: "\57";
}

.ti-drei-punkte:before {
  content: "\58";
}

.ti-dreipunkte-control:before {
  content: "\59";
}

.ti-dropdown-control:before {
  content: "\5a";
}

.ti-dropup-control:before {
  content: "\30";
}

.ti-druckansicht:before {
  content: "\31";
}

.ti-druckansicht-control:before {
  content: "\32";
}

.ti-e-book:before {
  content: "\33";
}

.ti-ebenen:before {
  content: "\34";
}

.ti-ebenen-control:before {
  content: "\35";
}

.ti-ebenenachhinten-control:before {
  content: "\36";
}

.ti-ebenenachvorne-control:before {
  content: "\37";
}

.ti-ebenenanordnung-control:before {
  content: "\38";
}

.ti-ebook-control:before {
  content: "\39";
}

.ti-eco-control:before {
  content: "\21";
}

.ti-editieren:before {
  content: "\22";
}

.ti-editieren-control:before {
  content: "\23";
}

.ti-einkaufstasche:before {
  content: "\24";
}

.ti-einkaufstasche-control:before {
  content: "\25";
}

.ti-eins:before {
  content: "\26";
}

.ti-einzug-erste-zeile:before {
  content: "\27";
}

.ti-elemente:before {
  content: "\28";
}

.ti-empfohlen:before {
  content: "\29";
}

.ti-empfohlen-control:before {
  content: "\2a";
}

.ti-farbpalette:before {
  content: "\2b";
}

.ti-farbpalette-control:before {
  content: "\2c";
}

.ti-filter:before {
  content: "\2d";
}

.ti-filter-control:before {
  content: "\2e";
}

.ti-flaeche-control:before {
  content: "\2f";
}

.ti-fliesstext:before {
  content: "\3a";
}

.ti-fliesstext-control:before {
  content: "\3b";
}

.ti-fragezeichen:before {
  content: "\3c";
}

.ti-fragezeichen-control:before {
  content: "\3d";
}

.ti-fuellfarbe1-control:before {
  content: "\3e";
}

.ti-fuellfarbe2-control:before {
  content: "\3f";
}

.ti-fuenf:before {
  content: "\40";
}

.ti-fusszeile:before {
  content: "\5b";
}

.ti-fusszeile-control:before {
  content: "\5d";
}

.ti-fusszeile-gerade-seiten-kopie-2:before {
  content: "\5e";
}

.ti-fusszeile-ungerade-seiten-kopie-3:before {
  content: "\5f";
}

.ti-ganznachhinten-control:before {
  content: "\60";
}

.ti-ganznachvorne2-control:before {
  content: "\7b";
}

.ti-ganznachvorne-control:before {
  content: "\7c";
}

.ti-glueckliche-kunden:before {
  content: "\7d";
}

.ti-grafiken-control:before {
  content: "\7e";
}

.ti-grossdruck-control:before {
  content: "\5c";
}

.ti-grossschreibung-control:before {
  content: "\e000";
}

.ti-grundeinstellungen1:before {
  content: "\e001";
}

.ti-grundeinstellungen2:before {
  content: "\e002";
}

.ti-grundeinstellungen-control:before {
  content: "\e003";
}

.ti-hardcover:before {
  content: "\e004";
}

.ti-hardcover-control:before {
  content: "\e005";
}

.ti-herz2-control:before {
  content: "\e006";
}

.ti-herz-control:before {
  content: "\e007";
}

.ti-herz-hamburg:before {
  content: "\e008";
}

.ti-hilfebereich:before {
  content: "\e009";
}

.ti-hilfebereich-control:before {
  content: "\e00a";
}

.ti-individualisierte-sonderausgabe:before {
  content: "\e00b";
}

.ti-info:before {
  content: "\e00c";
}

.ti-info-control:before {
  content: "\e00d";
}

.ti-international:before {
  content: "\e00e";
}

.ti-international-check-control:before {
  content: "\e00f";
}

.ti-international-control:before {
  content: "\e010";
}

.ti-internationaler-vertrieb:before {
  content: "\e011";
}

.ti-italic-control:before {
  content: "\e012";
}

.ti-kachelansicht:before {
  content: "\e013";
}

.ti-kacheln1-control:before {
  content: "\e014";
}

.ti-kacheln2-control:before {
  content: "\e015";
}

.ti-kamera-control:before {
  content: "\e016";
}

.ti-kommentar:before {
  content: "\e017";
}

.ti-kontakt:before {
  content: "\e018";
}

.ti-kontur-control:before {
  content: "\e019";
}

.ti-kopfzeile:before {
  content: "\e01a";
}

.ti-kopfzeile-control:before {
  content: "\e01b";
}

.ti-kopfzeile-gerade-seiten:before {
  content: "\e01c";
}

.ti-kopfzeile-ungerade-seiten-kopie:before {
  content: "\e01d";
}

.ti-kopieren:before {
  content: "\e01e";
}

.ti-kopieren-control:before {
  content: "\e01f";
}

.ti-korrektorat:before {
  content: "\e020";
}

.ti-kreative-freiheit:before {
  content: "\e021";
}

.ti-layout:before {
  content: "\e022";
}

.ti-lektorat:before {
  content: "\e023";
}

.ti-lineal1:before {
  content: "\e024";
}

.ti-lineal2:before {
  content: "\e025";
}

.ti-lineal-control:before {
  content: "\e026";
}

.ti-link-control:before {
  content: "\e027";
}

.ti-linksbuendig-control:before {
  content: "\e028";
}

.ti-listenansicht:before {
  content: "\e029";
}

.ti-listenansicht-control:before {
  content: "\e02a";
}

.ti-listenansicht-kreis:before {
  content: "\e02b";
}

.ti-loeschen:before {
  content: "\e02c";
}

.ti-loeschen-control:before {
  content: "\e02d";
}

.ti-lupe1:before {
  content: "\e02e";
}

.ti-lupe2:before {
  content: "\e02f";
}

.ti-lupe-control:before {
  content: "\e030";
}

.ti-marker-control:before {
  content: "\e031";
}

.ti-marketingplan:before {
  content: "\e032";
}

.ti-marketingvorlagen-marketing-app:before {
  content: "\e033";
}

.ti-materialauswahl:before {
  content: "\e034";
}

.ti-metadaten:before {
  content: "\e035";
}

.ti-minus-control:before {
  content: "\e036";
}

.ti-mitteilungen:before {
  content: "\e037";
}

.ti-mitteilungen-control:before {
  content: "\e038";
}

.ti-module:before {
  content: "\e039";
}

.ti-muster-formen:before {
  content: "\e03a";
}

.ti-neuauflage:before {
  content: "\e03b";
}

.ti-neun:before {
  content: "\e03c";
}

.ti-objekte-control:before {
  content: "\e03d";
}

.ti-online-shops:before {
  content: "\e03e";
}

.ti-ordner-geschlossen:before {
  content: "\e03f";
}

.ti-ordner-offen:before {
  content: "\e040";
}

.ti-paperback:before {
  content: "\e041";
}

.ti-paperback-control:before {
  content: "\e042";
}

.ti-papier1:before {
  content: "\e043";
}

.ti-papier2:before {
  content: "\e044";
}

.ti-pfeillinks2:before {
  content: "\e045";
}

.ti-pfeillinks-control:before {
  content: "\e046";
}

.ti-pfeilnachoben-control:before {
  content: "\e047";
}

.ti-pfeilrechts2:before {
  content: "\e048";
}

.ti-pfeilrechts-control:before {
  content: "\e049";
}

.ti-plus-control:before {
  content: "\e04a";
}

.ti-pressemitteilung:before {
  content: "\e04b";
}

.ti-proof1:before {
  content: "\e04c";
}

.ti-proof2:before {
  content: "\e04d";
}

.ti-proof3:before {
  content: "\e04e";
}

.ti-radiergummi-control:before {
  content: "\e04f";
}

.ti-raender:before {
  content: "\e050";
}

.ti-rahmen1-control:before {
  content: "\e051";
}

.ti-rahmen2-control:before {
  content: "\e052";
}

.ti-raster:before {
  content: "\e053";
}

.ti-raster2:before {
  content: "\e054";
}

.ti-raster-control:before {
  content: "\e055";
}

.ti-rechtsbuendig-control:before {
  content: "\e056";
}

.ti-rueckgaengig-control:before {
  content: "\e057";
}

.ti-schliessen-control:before {
  content: "\e058";
}

.ti-schlossauf-control:before {
  content: "\e059";
}

.ti-schlosszu-control:before {
  content: "\e05a";
}

.ti-schriftfarbe1-control:before {
  content: "\e05b";
}

.ti-schriftfarbe2-control:before {
  content: "\e05c";
}

.ti-sechs:before {
  content: "\e05d";
}

.ti-seitenraender:before {
  content: "\e05e";
}

.ti-seitenraender-aussen:before {
  content: "\e05f";
}

.ti-seitenraender-control:before {
  content: "\e060";
}

.ti-seitenraender-innen:before {
  content: "\e061";
}

.ti-seitenraender-oben:before {
  content: "\e062";
}

.ti-seitenraender-unten:before {
  content: "\e063";
}

.ti-shop-buchtypengrafiken-e-book:before {
  content: "\e064";
}

.ti-shop-buchtypengrafiken-hardcover:before {
  content: "\e065";
}

.ti-shop-buchtypengrafiken-paperback:before {
  content: "\e066";
}

.ti-sieben:before {
  content: "\e067";
}

.ti-skalieren1-control:before {
  content: "\e068";
}

.ti-skalieren2-control:before {
  content: "\e069";
}

.ti-sortieren:before {
  content: "\e06a";
}

.ti-sortieren-control:before {
  content: "\e06b";
}

.ti-soziales-engagement:before {
  content: "\e06c";
}

.ti-speichern:before {
  content: "\e06d";
}

.ti-spiegeln-horizontal1-control:before {
  content: "\e06e";
}

.ti-spiegeln-horizontal2-controlavav:before {
  content: "\e06f";
}

.ti-spiegeln-vertikal1-control:before {
  content: "\e070";
}

.ti-spiegeln-vertikal2-control:before {
  content: "\e071";
}

.ti-sprachauswahl-nur-sprache:before {
  content: "\e072";
}

.ti-sprachauswahl-sprache-land:before {
  content: "\e073";
}

.ti-taschenrechner-control:before {
  content: "\e074";
}

.ti-texte-control:before {
  content: "\e075";
}

.ti-textelemente:before {
  content: "\e076";
}

.ti-tipp:before {
  content: "\e077";
}

.ti-tipp-control:before {
  content: "\e078";
}

.ti-tools:before {
  content: "\e079";
}

.ti-ueberschriften:before {
  content: "\e07a";
}

.ti-ueberschriften-control:before {
  content: "\e07b";
}

.ti-uebersetzung-de-en:before {
  content: "\e07c";
}

.ti-underlined-control:before {
  content: "\e07d";
}

.ti-upload-control:before {
  content: "\e07e";
}

.ti-uploads-control:before {
  content: "\e07f";
}

.ti-verkaufsdaten:before {
  content: "\e080";
}

.ti-vertriebsdeals-einkaufsrabatte:before {
  content: "\e081";
}

.ti-verzeichnis:before {
  content: "\e082";
}

.ti-verzeichnis-control:before {
  content: "\e083";
}

.ti-vier:before {
  content: "\e084";
}

.ti-vorlagen-control:before {
  content: "\e085";
}

.ti-wagen-control:before {
  content: "\e086";
}

.ti-warenkorb-shop:before {
  content: "\e087";
}

.ti-wechsel2-control:before {
  content: "\e088";
}

.ti-wechsel-control:before {
  content: "\e089";
}

.ti-weiterlesen1-control:before {
  content: "\e08a";
}

.ti-weiterlesen2-control:before {
  content: "\e08b";
}

.ti-werbematerialien:before {
  content: "\e08c";
}

.ti-wuerfel-control:before {
  content: "\e08d";
}

.ti-zeichenabstand-control:before {
  content: "\e08e";
}

.ti-zeilenabstand:before {
  content: "\e08f";
}

.ti-zeilenabstand-control:before {
  content: "\e090";
}

.ti-zentriert-control:before {
  content: "\e091";
}

.ti-zielgruppe:before {
  content: "\e092";
}

.ti-zoom-in:before {
  content: "\e093";
}

.ti-zoom-out:before {
  content: "\e094";
}

.ti-zuschneiden-control:before {
  content: "\e095";
}

.ti-zwei:before {
  content: "\e096";
}
