.wrapper {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
    padding-bottom: var(--spacing-standard);
}

.form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-standard);
    padding-bottom: var(--spacing-standard);
}
