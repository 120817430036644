.container {
    display: grid;
    gap: var(--spacing-large);
}

.basicData {
    display: flex;
   flex-direction: column;
    gap: var(--spacing-large);
}

.series{
    display: flex;
    flex-direction: column;
}

.accordion {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-large);
}

.flex {
    display: flex;
    gap: var(--spacing-large);
}

.flexColumn {
    display: flex;
    flex-direction: column;
}

.question {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--spacing-standard);
}

.info {
    margin-right: auto;
}
