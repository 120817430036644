.wrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-small);
    font-weight: 400;
    isolation: isolate;
    font-size: var(--font-size-small);
}

/*.input:checked+.label {*/
/*    font-weight: 600;*/
/*}*/

.label {
    user-select: none;
}

.input {
    padding: var(--spacing-small) var(--spacing-medium);
    margin: 0;
    position: relative;
    width: 28px;
    height: 28px;
    appearance: none;
    border: 1px solid var(--color-mint);
    border-radius: var(--border-radius-forms);
    background-color: var(--color-background);
}

.input:disabled {
    border-color: var(--color-disabled);
}
.input:disabled+.label {
   color: var(--color-disabled)
}

.input:focus {
    outline: 3px solid var(--color-mint);
    outline-offset: -2px;
    background-color: var(--color-background);
}

.primary:checked {
    background-color: var(--color-mint);
}

.secondary:checked {
    background-color: var(--color-background);
}

.input:checked:disabled {
    background-color: var(--color-disabled);
}

.input::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 60%;
    height: 60%;
    background-color: black;
    clip-path: polygon(9.35% 51.64%, 0% 65%, 47.84% 98.48%, 95.37% 24.74%, 79.73% 14.65%, 41.42% 74.09%);
    transform: scale(0);
    transform-origin: bottom left;
    transition: 50ms 120ms transform ease-in-out;
}

input:checked::before {
    transform: scale(1);
}
