.ul {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-standard);
}

.entry {
  --entry-height: 180px;

  display: flex;
  background-color: var(--color-background);
  padding: var(--spacing-standard);
  gap: var(--spacing-standard);
  box-shadow: var(--box-shadow-small);
  border-radius: var(--border-radius);
  min-height: var(--entry-height);
  border-left: 5px solid var(--color-mint);
  cursor: pointer;
  outline: 2px solid transparent;
  transition: outline .2s ease-in-out;
  user-select: none;
}

.entry:hover {
  outline: 2px solid var(--color-mint);
}

.entry .meta {
  display: flex;
  flex-direction: column;
}

.entry .meta :last-child {
  margin-top: auto;
  color: var(--color-gray-dark);
  font-weight: 500;
}

.entry .figure {
  width: 120px;
  height: calc(var(--entry-height) - var(--spacing-standard) * 2);
}

.entry .title {
  font-size: var(--font-size-larger);
  font-weight: 500;
  margin-bottom: var(--spacing-medium);
}

.entry .subtitle {
  font-size: var(--font-size-normal);
  color: var(--color-gray-darker);
}

.entry .info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  margin-left: auto;
}
