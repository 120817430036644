.alert {
  padding: .5rem;
  display: inline-flex;
  border-radius: 3px;
  gap: 0.1rem;
}

.alert .alert-content {
  padding-left: 0.5rem;
}

.alert.error {
  color: white;
  background: var(--color-danger);
  font-weight: 600;
}

.alert.error .tredition-icon {
  font-size: 1.5rem;
}

.alert.warning {
  color: black;
  background: var(--color-warning);
}
